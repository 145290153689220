import React, {FC} from 'react'
import styles from './SiteCardBig.module.scss'
import { motion } from "framer-motion"
import { TSitesCard } from '../../utils/tupes'

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}

const SiteCardBig:FC<TSitesCard> = ({image, title, link}) => {

    return (
        <motion.li 
            className={styles.item}
            variants={cardAnimation}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <a className={styles.link_image} href={link} target='_blank' rel='noopener noreferrer'>
                <img className={styles.image} src={image} alt='сайт'/>
            </a>
            <div className={styles.info}>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.box_button}>
                    <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://t.me/Dmitry_Tihonoff' aria-label='телеграмм'>заказать</a>
                    <a className={styles.link} target='_blank' rel='noopener noreferrer' href={link}>на сайт</a>
                </div>
            </div>
        </motion.li>
    )
}

export default SiteCardBig