import React, {FC, useEffect} from 'react'
import {Helmet} from "react-helmet"
import styles from './Main.module.scss'
import { InitialPage } from '../../components/InitialPage/InitialPage'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { Prices } from '../../components/Prices/Prices'
import { AboutMe } from '../../components/AboutMe/AboutMe'
import { SliderTechnologies } from '../../components/SliderTechnologies/SliderTechnologies'
import { SliderAdvantages } from '../../components/SliderAdvantages/SliderAdvantages'
import { Advantages } from '../../components/Advantages/Advantages'
import { TMenu } from '../../utils/tupes'
import { SitesListMin } from '../../components/SitesListMin/SitesListMin'
import Chestnost from '../../components/Chestnost/Chestnost'

export const Main:FC<TMenu> = ({isOpen}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <section className={styles.main}>
            <Helmet>
                <title>Частный вебмастер – веб-разработчик Tихонов Дмитрий</title>
                <meta name="description" content="Создание современных, продающих сайтов под ключ частный мастер, продвижение сайтов, сопровождение сайтов | Разработка сайта от частного мастера."/>
                <meta property="og:title" content="Частный вебмастер – веб-разработчик Tихонов Дмитрий"/>
                <meta property="og:description" content="Создание современных, продающих сайтов под ключ частный мастер, продвижение сайтов, сопровождение сайтов | Разработка сайта от частного мастера."/>
                <link rel="canonical" href="https://tihonov-studio.ru/" />
                <meta property="og:url" content="https://tihonov-studio.ru/"/>
            </Helmet>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <AboutMe/>
            <Advantages/>
            <SliderTechnologies/>
            <Prices/>
            <SliderTechnologies/>
            <SitesListMin/>
            <Chestnost/>
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </section>
    )
}