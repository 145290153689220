import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './Chestnost.module.scss'
import img_1 from "../../image/Chestnost/img_1.png"
import img_2 from "../../image/Chestnost/img_2.png"
import img_3 from "../../image/Chestnost/img_3.png"
import img_4 from "../../image/Chestnost/img_4.png"

const titleAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5}
    } 
}


const Chestnost:FC = () => {
    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}} 
            >
                <motion.h2 className={styles.title} variants={titleAnimation}>Буду честным с&#160;вами</motion.h2>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <img className={styles.box_image} src={img_1} alt='картинка'/>
                            <div  className={styles.box_info}>
                                <h3 className={styles.item_title}>Я не сделаю вам гавно-сайт</h3>
                                <p className={styles.item_text}>Под оффером: сайт за 1 день за 5&#160;000&#160;₽</p>
                            </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <img className={styles.box_image} src={img_2} alt='картинка'/>
                            <div  className={styles.box_info}>
                                <h3 className={styles.item_title}>Я не буду продавать по заявкам вместо&#160;вас</h3>
                                <p className={styles.item_text}>Я выстраиваю систему получения лидов, а перезванивать по заявкам это уже на ваших плечах</p>
                            </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <img className={styles.box_image} src={img_3} alt='картинка'/>
                            <div  className={styles.box_info}>
                                <h3 className={styles.item_title}>Если для вас важна цена, а не результат</h3>
                                <p className={styles.item_text}>Тогда вам лучше покинуть мой сайт и найти другого веб-разработчика</p>
                            </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <img className={styles.box_image} src={img_4} alt='картинка'/>
                            <div  className={styles.box_info}>
                                <h3 className={styles.item_title}>Я делаю продукт, который приносит вам&#160;деньги</h3>
                                <p className={styles.item_text}>Он должен нравится не вам, а клиентам и приносить вам деньги, конвертировать посетителей в заказы</p>
                            </div>
                    </motion.li>
                </ul>
        </motion.section>
    )
}

export default Chestnost


