import React, {FC} from 'react'
import { arrSitesSlider } from '../../utils/data'
import styles from './SliderSites.module.scss'
import { v4 as uuidv4 } from 'uuid'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay} from "swiper"
import "./Feedback.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"


const SliderSites:FC = () => {

    return (
        <section className={styles.main}>
        <div className={styles.box}>
                <div className={styles.slider}>
                    <Swiper
                            spaceBetween={15}
                            slidesPerView={1.3}
                            centeredSlides={true}
                            loop={true}
                            autoplay={{
                                delay: 2000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                type: 'fraction'
                            }}
                            breakpoints={{

                                1400: {
                                    slidesPerView: 3.5,
                                    spaceBetween: 5,
                                },

                                768: {
                                    slidesPerView: 1.7,
                                    spaceBetween: 5,
                                },

                                450: {
                                    slidesPerView: 1.2,
                                    spaceBetween: 5,
                                },

                                320: {
                                    slidesPerView: 1.2,
                                    spaceBetween: 5,
                                },
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                            >
                            {arrSitesSlider.map((item, index) => {
                                const keyUid = uuidv4()
                                    return(
                                        <SwiperSlide key={keyUid}>
                                            <a className={styles.link} target='_blank' rel='noopener noreferrer' href={item.link}>
                                                <img className={styles.image} src={item.image} alt='сайт'/>
                                            </a>
                                        </SwiperSlide>
                                        ) 
                                        })}        
                    </Swiper>
                </div>
            </div>
    </section>
    )
}

export default SliderSites