import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './AboutMe.module.scss'
import you from '../../image/you.webp'

const titleAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: .5 }
    }
}

const textAnimation = {
    hidden: {
        x: 200,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: {ease: "easeOut", duration: .7 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}




export const AboutMe:FC = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}} 
            id='AboutUs'
            >
            <div className={styles.container}>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image}  src={you} alt='фотография'/>
                    <div className={styles.image_info}>
                        <h3 className={styles.image_title}>Тихонов Дмитрий</h3>
                        <p className={styles.image_text}>веб‍-‍разработчик</p>
                    </div>
                </motion.div>
                    
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Частный вебмастер <span>веб&#8205;-&#8205;разработчик</span></motion.h1>
                    <motion.p className={styles.text} variants={textAnimation}>Уважаемые клиенты и партнёры, рад приветствовать Вас на моем web-сайте. Меня зовут Тихонов Дмитрий, я независимый веб-разработчик, UX/UI-дизайнер и маркетолог с опытом работы более 10 лет. Специализируюсь на разработке современных сайтов с премиальным дизайном, ориентированных на продажи.</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}>Надеюсь, вы ищите современный и продающий веб-сайт, ведь я разрабатываю сайты, которые:</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}><span>&#10003;</span> написаны живым кодом на лучшей JavaScript-библиотеке, а не собраны на шаблоне или конструкторе;</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}><span>&#10003;</span> осмысленно нарисованы под потребителя и привлекают внимание целевой аудитории;</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}><span>&#10003;</span> имеют самый современный функционал, красивую анимацию и продуманное юзабилити;</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}><span>&#10003;</span> адаптированы под все устройства от мобильных телефонов до настольных компьютеров;</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}><span>&#10003;</span> имеют все необходимые SEO атрибуты, подключены к Яндекс.Метрике и Яндекс.Вебмастер.</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}>Хороший сайт должен не только продать вашу услугу или товар, но и выделить вас на фоне конкурентов, а так же подчеркнуть вашу статусность.</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}>Если вам нужен сайт не как у конкурентов, а в несколько раза лучше - вы попали по адресу, буду рад вам помочь!</motion.p>
                    <motion.p className={styles.text} variants={textAnimation}>Пишите/Звоните - расскажу, что такое хороший сайт и посоветую лучший вариант для вашей сферы деятельности.</motion.p>
                </div>
            </div>
        </motion.section>
    )
}