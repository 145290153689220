import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './SitesList.module.scss'
import { arrSitesMinAll } from '../../utils/data'
import SiteCardBig from '../SiteCardBig/SiteCardBig'
import { v4 as uuidv4 } from 'uuid'

export const SitesList:FC = () => {


    const titleAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const textAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}} 
            >
            <div className={styles.container}>
                <motion.h1 className={styles.title} variants={titleAnimation}>Портфолио <span>моих&#160;работ</span></motion.h1>
                <motion.p className={styles.text} variants={textAnimation}>Мое портфолио-не просто сайты. За каждым целая история, которую картинкой или ссылкой не покажешь.</motion.p>    
                <motion.ul className={styles.list} variants={textAnimation}>
                    {arrSitesMinAll.map((item, index) => {
                        const keyUid = uuidv4()
                            return(
                                <SiteCardBig
                                    image={item.image}
                                    title={item.title}
                                    link={item.link}
                                    key={keyUid}
                                />
                            ) 
                    })}         
                </motion.ul>
            </div>
        </motion.section>    
    )
}