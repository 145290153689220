import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import {Helmet} from "react-helmet"
import styles from '../WebsitePresentation/WebsitePresentation.module.scss'
import image_2 from '../../image/Info/img_26.webp'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TModalOpen } from '../../utils/tupes'
import { Advantages } from '../../components/Advantages/Advantages'
import i from '../../image/Chestnost/i.png'
import Chestnost from '../../components/Chestnost/Chestnost'
import SliderSites from '../../components/SliderSites/SliderSites'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}


export const CorporateWebsite:FC<TModalOpen> = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}} 
            >
            <Helmet>
                <title>Создание корпоративного сайта Мытищи, Королев, Балашиха</title>
                <meta name="description" content="Создание корпоративных сайтов под ключ частным веб‍-‍разработчиком. Все этапы разработки  корпоративного сайта."/>
                <meta property="og:title" content="Создание корпоративного сайта Мытищи, Королев, Балашиха"/>
                <meta property="og:description" content="Создание корпоративных сайтов под ключ частным веб‍-‍разработчиком. Все этапы разработки  корпоративного сайта."/>
            </Helmet>
            <BreadCrumbs
                text={'Корпоративный сайт'}
            />
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Создание <span>корпоративного&#160;сайта</span></motion.h1>
                    <motion.p className={styles.subtitle} variants={subtitleAnimation}>От <span>50 000₽</span> от <span>10</span> дней</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>Сегодня ни одна серьезная компания, стремящаяся занять заметное место в своем сегменте рынка, не может обходиться без корпоративного сайта. Разработка корпоративного сайта поможет вашей компании занять лучшие позиции, привлечь новых клиентов и партнеров, расширить рынки сбыта. При росте и развитии компании корпоративный сайт сменяет сайт-визитку - именно так вы получите возможность более активно и результативно продавать свои товары и услуги через сеть.</motion.p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79258898545' aria-label='связаться вацап'>Обсудить проект</a>
                    </div>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image_2} alt='сайты'/>
                    <div className={styles.podskazka}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_one}>
                                <p className={styles.podskazka_text}>Никаких конструкторов - только ручная валидная верстка</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_one}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text}>
                                <p className={styles.podskazka_text}>Авторский эксклюзивный дизайн</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_two}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_two}>
                                <p className={styles.podskazka_text}>Смысловая маркетинговая упаковка вашей компании и продукции в многостраничный корпоративный ресурс</p>
                            </div>
                        </span>
				    </div>
                </motion.div>
            </div>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <h2 className={styles.title}>Что такое <span>корпоративный&#160;сайт</span></h2>
                <p className={styles.text}>Корпоративный сайт – это портал для бизнеса, необходимый элемент для успешной работы любой компании, будь то крупная международная организация или небольшое предприятие. В широком смысле — это представительство компании в интернете.</p>
                <p className={styles.text}>Hа корпоративном сайте представлена подробная информация о деятельности организации или предприятия: описание услуг, история развития, условия сотрудничества, онлайн-сервисы, открытые вакансии, интересные предложения, новости и прочие факты и события, отражающие деятельность компании. </p>
                <p className={styles.text}>Повышение продаж не является главной задачей при создании корпоративного сайта. Он, безусловно, влияет на принятие решения о приобретении товаров или услуг, особенно в сегменте B2B, однако ключевая цель — выстраивание полноценной двусторонней коммуникации с разными группами общественности.</p>
                <p className={styles.text}>Корпоративный сайт — это своего рода «лицо» компании, поэтому критическое значение имеют грамотный дизайн и архитектура ресурса, призванные производить правильное впечатление на посетителей. Сайт должен соответствовать общему стилю компании.</p>
                <p className={styles.text}>Функционал корпоративного сайта может быть крайне разнообразным, все зависит только от Вашего желания и целей будущего сайта. Корпоративный сайт — это ресурс с большими объемами инфрмации, сервисов и функций.</p>
                </motion.div>
                <Advantages/>
                <SliderSites/>
                <motion.div 
                    className={styles.box_info}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.1, once: true}}
                >
                <motion.h3 className={styles.title} variants={textAnimation}>Из чего состоит <span>корпоративный&#160;сайт</span></motion.h3>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Логотип</h4>
                        <p className={styles.item_text}>Логотип — это первое что видит пользователь. На западе люди привыкли смотреть слева-направо, по этому логотип располагают обычно в верхнем левом углу сайта, чтобы сразу понять кто владелец данного сайта.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Главная страница</h4>
                        <p className={styles.item_text}>Необходимо ответсвенно подойти к этому блоку, ведь, как мы говорили ранее, сайт — лицо компании, а главная страница — то, что видит клиент впервые. У Вас не будет возможности произвести второе впечатление. На главной странице должна быть зафиксирована основная информация о Вашей компании. </p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Информация о компании</h4>
                        <p className={styles.item_text}>В идеале описать историю, этапы развития, достижения, партнеров. При этом важно структурировать информацию, чтобы из-за монотонности подачи материала сплошным текстом- «простыней» читатели не пропустили полезные и значимые факты, способные повлиять на сотрудничество.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Товары и услуги</h4>
                        <p className={styles.item_text}> В этом разделе сайта описывают с необходимой степенью подробности, продукцию, товары, услуги. Если спектр предложений велик, то лучше создать подразделы с возможностью выбора конкретной продукции или услуг. </p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Вопросы и ответы</h4>
                        <p className={styles.item_text}>Данный раздел позволяет опубликовать часто задаваемые вопросы и ответы на них, сняв определенную нагрузку с лиц, дающих ответы, скажем, по телефону "горячей линии".</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Личный кабинет</h4>
                        <p className={styles.item_text}>На многих корпоративных сайтах крупных компаний Вы могли видеть форму авторизации. Это может быть актуально как для сотрудников, так и для клиентов для доступа к разделам закрытым для общего доступа.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Новости</h4>
                        <p className={styles.item_text}>Посетителю чаще интересны "горячие" новости. Информация данного раздела должна обновляться с определенной периодичностью. "Устаревшие" новости совсем не обязательно удалять. Лучше размещать их в специальном разделе, например, "Архив новостей".</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Контакты</h4>
                        <p className={styles.item_text}>Предоставьте Вашим потенциальным клиентом исчерпывающую информацию о Ваших контактных данных: укажите адрес, номер телефона, электронную почту, ссылки на социальные сети, адрес офиса(желательно со схемой проезда на карте), график работы, реквизиты.</p>
                    </motion.li>
                </ul>
                </motion.div>
            <Chestnost/>
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </motion.section>
    )
}