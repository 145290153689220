import React, {FC} from 'react'
import { motion } from "framer-motion"
import styles from './SitesListMin.module.scss'
import { arrSitesMin } from '../../utils/data'
import SiteCardBig from '../SiteCardBig/SiteCardBig'
import { v4 as uuidv4 } from 'uuid'
import { Link } from 'react-router-dom'

export const SitesListMin:FC = () => {


    const titleAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .3 }
        }
    }
    
    const textAnimation = {
        hidden: {
            y: 70,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}} 
            >
            <div className={styles.container}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Портфолио <span>моих&#160;работ</span></motion.h2>
                <motion.p className={styles.text} variants={textAnimation}>Мое портфолио-не просто сайты. За каждым целая история, которую картинкой или ссылкой не покажешь.</motion.p>    
                <motion.ul className={styles.list} variants={textAnimation}>
                    {arrSitesMin.map((item, index) => {
                        const keyUid = uuidv4()
                        
                            return(
                                <SiteCardBig
                                    image={item.image}
                                    title={item.title}
                                    link={item.link}
                                    key={keyUid}
                                />
                            ) 
                    })}         
                </motion.ul>
                <Link  className={styles.button} to='/portfolio'>Смотреть все работы <span className={styles.glare}></span></Link>
            </div>
        </motion.section>    
    )
}