import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import styles from '../WebsitePresentation/WebsitePresentation.module.scss'
import image_2 from '../../image/Info/img_22.webp'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TModalOpen } from '../../utils/tupes'
import { Advantages } from '../../components/Advantages/Advantages'
import i from '../../image/Chestnost/i.png'
import Chestnost from '../../components/Chestnost/Chestnost'
import SliderSites from '../../components/SliderSites/SliderSites'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}



export const PersonalWebsite:FC<TModalOpen> = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main} 
            id='prices'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}
            >
            <BreadCrumbs
                text={'Персональный сайт'}
            />
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Создание <span>персонального сайта</span></motion.h1>
                    <motion.p className={styles.subtitle} variants={subtitleAnimation}>От <span>25 000₽</span> от <span>5</span> дней</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>Создание сайта,  который поможет заявить о себе как о специалисте и рассказать о вашем бизнесе, услуге или продукте.</motion.p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79258898545' aria-label='связаться вацап'>Обсудить проект</a>
                    </div>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image_2} alt='сайты'/>
                    <div className={styles.podskazka}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_one}>
                                <p className={styles.podskazka_text}>Никаких конструкторов - только ручная валидная верстка</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_one}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text}>
                                <p className={styles.podskazka_text}>Авторский продающий WOW-дизайн</p>
                            </div>
                        </span>
				    </div>
                    <div className={styles.podskazka_two}>
                        <span className={styles.podskazka_box}>
                            <img className={styles.podskazka_image}  src={i}  alt="i"/>
                            <div className={styles.podskazka_box_text_two}>
                                <p className={styles.podskazka_text}>Смысловая маркетинговая упаковка вашей компании и продукта в одностраничный продающий сайт</p>
                            </div>
                        </span>
				    </div>
                </motion.div>
            </div>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <h2 className={styles.title}>Что такое <span>персональный&#160;сайт</span></h2>
                <p className={styles.text}>Если Вы хотите рассказать о себе как о специалисте или развить личный бренд, Вам обязательно потребуется персональный сайт.  Это прекрасная возможность, когда Вы можете во всей красе продемонстрировать свои навыки, похвалиться достижениями, обозначить свои компетенции и поделиться портфолио своих работ.</p>
                <p className={styles.text}>Такой сайт отлично подойдет для ИП, творческих людей и всех тех, кто работает на себя, и кому не нужны тяжеловестные корпоративные сайты или кому недостаточно простого малофункционального сайта-визитки.</p>
                <p className={styles.text}>Имея персональный сайт, Вам проще продвигать свои товары или услуги. Повышаются шансы, что потенциальные клиенты узнают о Вас, сделают покупку или оформят заказ, ведь сейчас все больше и больше людей предпочитают получать информацию и покупать через интернет. Также Вы сможете повысить свою узнаваемость и авторитетность, если сайт будет грамотно оформлен и наполнен качественным контентом.</p>
                </motion.div>
                <Advantages/>
                <SliderSites/> 
                <motion.div 
                    className={styles.box_info}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.1, once: true}}
                    >
                <motion.h3 className={styles.title} variants={textAnimation}>Из чего состоит <span>персональный&#160;сайт</span></motion.h3>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Логотип</h4>
                        <p className={styles.item_text}>Логотип — это первое что видит пользователь. На западе люди привыкли смотреть слева-направо, по этому логотип располагают обычно в верхнем левом углу сайта, чтобы сразу понять кто владелец данного сайта.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>О себе</h4>
                        <p className={styles.item_text}>Пусть посетитель знает, кто Вы парень или девушка, мужчина или женщина. Напишите сколько времени Вы занимаетесь своим делом. Чем более подробную информацию Вы предоставите о себе, тем больше шансов, что именно у Вас закажут ту или иную услуги или купят товар.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Услуги или товары</h4>
                        <p className={styles.item_text}>Главное написать, предельно ясно, чем конкретно Вы занимаетесь: Веб-дизайн, разработка, видео, копирайтинг, брендинг и т.д. </p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Портфолио</h4>
                        <p className={styles.item_text}>Личное портфолио на сайте должно определять интересен ли сайт для пользователя или нет. Ваше портфолио должно состоять из высококачественных снимков  работ, и всегда должны содержать ссылку на те работы которые Вы сделали. Какими навыками нужно обладать чтобы завершить подобный проект, и при помощи каких программ Вы сделали эту работу.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Блог</h4>
                        <p className={styles.item_text}>Блог — это всегда хорошо. Блог с Вашими мыслями, Вашими знаниями, Вашим опытом — показывает что Вы знаете и умеете. Это позволит динамически развиваться Вашему сайту.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Контактная информация</h4>
                        <p className={styles.item_text}>Ваша контактная информация должна быть на виду и иметь легкий доступ. Пусть люди знают что могут связаться с Вами, через e-mail, чат или телефон. Также используйте формы для быстрой связи  через сайт.</p>
                    </motion.li>
                </ul>
                </motion.div>
                <Chestnost/>      
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </motion.section>
    )
}