import React, {FC, useEffect} from 'react'
import { motion } from "framer-motion"
import {Helmet} from "react-helmet"
import styles from '../WebsitePresentation/WebsitePresentation.module.scss'
import image_2 from '../../image/Info/img_1.webp'
import { Info } from '../../components/Info/Info'
import { arrInfo } from '../../utils/data'
import { BreadCrumbs } from '../../components/BreadCrumbs/BreadCrumbs'
import { TModalOpen } from '../../utils/tupes'
import { Advantages } from '../../components/Advantages/Advantages'
import Chestnost from '../../components/Chestnost/Chestnost'
import SliderSites from '../../components/SliderSites/SliderSites'

const titleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const subtitleAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}


const cardAnimation = {
    hidden: {
        y: 70,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .9}
    } 
}

export const OnlineStore:FC<TModalOpen> = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <motion.section 
            className={styles.main} 
            id='prices'
            initial='hidden'
            whileInView='visible'
            viewport={{once: true}}
            >
            <Helmet>
                <title>Создание интернет магазина.</title>
                <meta name="description" content="Создание интернет магазина под ключ."/>
                <meta property="og:title" content="Создание интернет магазина."/>
                <meta property="og:url" content="https://tihonov-studio.ru/internet-magazin"/>
                <meta property="og:description" content="Создание интернет магазина под ключ."/>
            </Helmet>
            <BreadCrumbs
                text={'Интернет-магазин'}
            />
            
            <div className={styles.container}>
                <div className={styles.box}>
                    <motion.h1 className={styles.title} variants={titleAnimation}>Создание <span>интернет&#160;магазина</span></motion.h1>
                    <motion.p className={styles.subtitle} variants={subtitleAnimation}>От <span>90 000₽</span> от <span>30</span> дней</motion.p>
                    <motion.p className={styles.text} variants={subtitleAnimation}>Возможность выбирать и покупать товар, не покидая дома, и при этом платить меньше - вот главные привлекательные моменты для потенциальных покупателей, обеспечивающие владельцу интернет-магазина устойчивый рост дохода. Создание сайта интернет-магазина возможна и даже необходима также для оффлайн-магазинов, стремящихся создать максимум удобства для своих клиентов.</motion.p>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79258898545' aria-label='связаться вацап'>Обсудить проект</a>
                    </div>
                </div>
                <motion.div className={styles.box_image} variants={imageAnimation}>
                    <img className={styles.image} src={image_2} alt='сайты'/>
                </motion.div>
            </div>
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                variants={boxAnimation}
                >
                <h2 className={styles.title}>Что такое <span>интернет&#8205;-&#8205;магазин</span></h2>
                <p className={styles.text}>Интернет магазин — это сайт с представленными на нем продуктами, где покупатель может выбрать и оплатить товар. Такой вид сайта считается сложным по своей конструкции, т.к. имеет разветвленную структуру с множеством переходов. Главное преимущество такого вида торговли в отсутствии необходимости нанимать большое количество персонала, арендовать здание и иметь склад с запасами. Для покупателя безусловным плюсом становится шопинг не выходя из дома.</p>
                <p className={styles.text}>Прежде всего, интернет-магазин может быть направлен на продажу товаров и услуг одной компании другим (B2B), что предполагает большие объемы торговли и высокую скорость согласования, и на продажу продукции конечным потребителям (B2C – такой тип виртуальных торговых платформ наиболее распространен).</p>
                <p className={styles.text}>Особенностями интернет-магазина являются возможность предложить самый обширный ассортимент товаров и возможность продавать товары, которых на данное время у продавца нет в наличии (дропшиппинг), возможность круглосуточного приема заказов, удобство оплаты товара благодаря большому выбору способов - электронными деньгами, банковской картой, SMS-платежами и т.д. Интернет магазин дает покупателю возможность перед покупкой  детально изучить характеристики товара и отзывы о нем других клиентов, оформить доставку на любое удобное время.</p>
                <p className={styles.text}>Основное отличие интернет-магазина от сайта-каталога — наличие корзины и возможность онлайн-оплаты.</p>
            </motion.div>
            <Advantages/>
            <SliderSites/> 
            <motion.div 
                className={styles.box_info}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.1, once: true}}
                >
                <motion.h3 className={styles.title} variants={textAnimation}>Из чего состоит <span>интернет&#8205;-&#8205;магазин</span></motion.h3>
                <ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Главная страница</h4>
                        <p className={styles.item_text}>Первое, что видят посетители, когда заходят на сайт – главную страницу. Поэтому её дизайн должен привлекать внимание и побуждать гостей перейти в другие разделы интернет-магазина. Обычно на главной странице размещаются рекламные баннеры, блоки с акциями, а также кратким списком товаров (с новинками, хитами продаж, недавно просмотренными продуктами).</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>О компании</h4>
                        <p className={styles.item_text}>Очень важно сделать отдельную страницу, на которой будет освещена основная информация о Вашей компании. Желательно писать о сфере деятельности, о сотрудничестве с производителями, о грамотной ценовой политике и удобстве обслуживания клиентов. При этом все сведения должны быть реальными и настоящими, а не выдуманными и «высосанными из пальца».</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Каталог продукции</h4>
                        <p className={styles.item_text}>Каталог товаров – основной структурный элемент сайта магазина. Он содержит то, зачем приходит пользователь – товарное предложение. Для удобства покупателей его структурируют с помощью нескольких эффективных инструментов(категории, подкатегории, карточки товаров, фильтры).</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Акции</h4>
                        <p className={styles.item_text}>Распродажи и акции являются очень важным инструментом для продвижения товаров/услуг, моментально привлекающим внимание посетителей, большинство из которых всегда ищут, где бы подешевле купить тот или иной продукт. На одной из страниц желательно выводить все товары, на которые предоставляется скидка.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Личный кабинет</h4>
                        <p className={styles.item_text}>В интернет-магазинах, предусматривающих возможность регистрации, должен присутствовать еще один структурный компонент – личный кабинет покупателя. В этом месте хранится информация пользователя(история заказов, сведения о накопленных бонусах, функции настройки профиля).</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Доставка/Оплата</h4>
                        <p className={styles.item_text}>Раздел с описанием условий или способов оплаты заказов и доставки купленных продуктов. В некоторых ситуациях такие сведения располагают на одной странице, но более рационально сделать отдельные разделы «Доставка» и «Оплата».</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Корзина товаров</h4>
                        <p className={styles.item_text}>В данном разделе происходит процесс покупки: клиенты видят общую сумму, проверяют и редактируют списки добавленных позиций, переходят по ссылке для последующей оплаты. Корзина должна быть доступна с любого места сайта, предоставлять возможность быстро вернуться к правке заказа.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <h4 className={styles.item_title}>Контакты</h4>
                        <p className={styles.item_text}>Предоставьте Вашим потенциальным клиентом исчерпывающую информацию о Ваших контактных данных: укажите адрес, номер телефона, электронную почту, ссылки на социальные сети, адрес главного офиса, точек самовывоза (желательно со схемой проезда на карте), график работы, реквизиты.</p>
                    </motion.li>
                </ul>
            </motion.div>
            <Chestnost/>  
            <Info
                title={ arrInfo[0].title}
                text={ arrInfo[0].text}
                image={ arrInfo[0].image}
                title_button={arrInfo[0].title_button}
                link={arrInfo[0].link}
            />
        </motion.section>
    )
}